<template>
  <v-container fluid>
    <v-data-table
      item-key="siloContentId"
      :headers="headers"
      :items="contents"
      :items-per-page="18"
    >
      <template v-slot:[`item.name`]="{ item }">
        <span class="overline">{{ item.name }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="permitted('SiloContents.Update') && item.companyId == companyId"
          icon
          @click="() => $router.push(`/contents/${item.siloContentId}`)"
        >
          <v-icon color="primary">mdi-pen</v-icon>
        </v-btn>

        <v-btn
          icon
          @click="
            () => {
              removeDialog = true;
              selectedId = item.siloContentId;
            }
          "
          v-if="permitted('SiloContents.Delete') && item.companyId == companyId"
        >
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <delete-dialog
      :dialog="removeDialog"
      v-on:cancel-dialog="removeDialog = false"
      v-on:confirm-delete="remove(selectedId)"
    />
  </v-container>
</template>

<script>
import DeleteDialog from "../common/DeleteDialog.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "SiloPropertyList",

  components: {
    DeleteDialog,
  },

  data() {
    return {
      siloPropertyModel: false,
      removeDialog: false,
      selectedId: null,
      headers: [
        { text: this.$t("contents.fields.name"), value: "name" },
        {
          text: this.$t("contents.fields.weightByCubicMeter"),
          value: "weightByCubicMeter",
        },
        { text: "", value: "actions" },
      ],
    };
  },

  computed: {
    ...mapState("contents", ["contents"]),
    ...mapGetters("users", ["companyId"]),
  },

  methods: {
    ...mapActions("contents", ["getSiloContents", "deleteSiloContent"]),

    async remove(id) {
      if (id == null) return;

      await this.deleteSiloContent(id);
      this.removeDialog = false;
      this.selectedId = null;
    },
  },

  async created() {
    await this.getSiloContents();
  },
};
</script>